.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%; }
    .input-group > .form-control,
    .input-group > .form-control-plaintext,
    .input-group > .custom-select,
    .input-group > .custom-file {
      position: relative;
      flex: 1 1 auto;
      width: 1%;
      margin-bottom: 0; }
      .input-group > .form-control + .form-control,
      .input-group > .form-control + .custom-select,
      .input-group > .form-control + .custom-file,
      .input-group > .form-control-plaintext + .form-control,
      .input-group > .form-control-plaintext + .custom-select,
      .input-group > .form-control-plaintext + .custom-file,
      .input-group > .custom-select + .form-control,
      .input-group > .custom-select + .custom-select,
      .input-group > .custom-select + .custom-file,
      .input-group > .custom-file + .form-control,
      .input-group > .custom-file + .custom-select,
      .input-group > .custom-file + .custom-file {
        margin-left: -1px; }
    .input-group > .form-control:focus,
    .input-group > .custom-select:focus,
    .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
      z-index: 3; }
    .input-group > .custom-file .custom-file-input:focus {
      z-index: 4; }
    .input-group > .form-control:not(:last-child),
    .input-group > .custom-select:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .form-control:not(:first-child),
    .input-group > .custom-select:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
    .input-group > .custom-file {
      display: flex;
      align-items: center; }
      .input-group > .custom-file:not(:last-child) .custom-file-label,
      .input-group > .custom-file:not(:last-child) .custom-file-label::after {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
      .input-group > .custom-file:not(:first-child) .custom-file-label {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
  
  .input-group-prepend,
  .input-group-append {
    display: flex; }
    .input-group-prepend .btn,
    .input-group-append .btn {
      position: relative;
      z-index: 2; }
      .input-group-prepend .btn:focus,
      .input-group-append .btn:focus {
        z-index: 3; }
    .input-group-prepend .btn + .btn,
    .input-group-prepend .btn + .input-group-text,
    .input-group-prepend .input-group-text + .input-group-text,
    .input-group-prepend .input-group-text + .btn,
    .input-group-append .btn + .btn,
    .input-group-append .btn + .input-group-text,
    .input-group-append .input-group-text + .input-group-text,
    .input-group-append .input-group-text + .btn {
      margin-left: -1px; }
  
  .input-group-prepend {
    margin-right: -1px; }
  
  .input-group-append {
    margin-left: -1px; }
  
  .input-group-text {
    display: flex;
    align-items: center;
    padding: 0.625rem 0.75rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #adb5bd;
    text-align: center;
    white-space: nowrap;
    background-color: #fff;
    border: 1px solid #cad1d7;
    border-radius: 0.25rem; }
    .input-group-text input[type="radio"],
    .input-group-text input[type="checkbox"] {
      margin-top: 0; }
  
  .input-group-lg > .form-control:not(textarea),
  .input-group-lg > .custom-select {
    height: calc(1.5em + 1.75rem + 2px); }
  
  .input-group-lg > .form-control,
  .input-group-lg > .custom-select,
  .input-group-lg > .input-group-prepend > .input-group-text,
  .input-group-lg > .input-group-append > .input-group-text,
  .input-group-lg > .input-group-prepend > .btn,
  .input-group-lg > .input-group-append > .btn {
    padding: 0.875rem 1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.3rem; }
  
  .input-group-sm > .form-control:not(textarea),
  .input-group-sm > .custom-select {
    height: calc(1.5em + 0.5rem + 2px); }
  
  .input-group-sm > .form-control,
  .input-group-sm > .custom-select,
  .input-group-sm > .input-group-prepend > .input-group-text,
  .input-group-sm > .input-group-append > .input-group-text,
  .input-group-sm > .input-group-prepend > .btn,
  .input-group-sm > .input-group-append > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
    line-height: 1.5;
    border-radius: 0.2rem; }
  
  .input-group-lg > .custom-select,
  .input-group-sm > .custom-select {
    padding-right: 1.75rem; }
  
  .input-group > .input-group-prepend > .btn,
  .input-group > .input-group-prepend > .input-group-text,
  .input-group > .input-group-append:not(:last-child) > .btn,
  .input-group > .input-group-append:not(:last-child) > .input-group-text,
  .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
  .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  
  .input-group > .input-group-append > .btn,
  .input-group > .input-group-append > .input-group-text,
  .input-group > .input-group-prepend:not(:first-child) > .btn,
  .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
  .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
  .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }